import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/productivity_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/productivity_blog_header.png");
const section_1 = require("../../../assets/img/blogs/productivity_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/productivity_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/productivity_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/productivity_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/productivity_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/productivity_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/productivity_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/productivity_blog_img_9.png");
const section_9 = require("../../../assets/img/blogs/productivity_blog_img_8.png");
const section_10 = require("../../../assets/img/blogs/productivity_blog_img_11.png");
const section_11 = require("../../../assets/img/blogs/productivity_blog_img_10.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Boosting Agent Efficiency and Productivity with Seamless Shared Inbox Integration"
        description="Learn AI predictions for 2024. The top predictions are open-source models, AI regulations, and leaner AI alternatives. "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt productivity_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog  color-white line-height-18">
                    Maximizing Agent Efficiency: Harnessing AI Assistance for
                    Enhanced Productivity with Shared Inbox
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog color-white">
                    Maximizing Agent Efficiency:
                    <br /> Harnessing AI Assistance for
                    <br /> Enhanced Productivity with
                    <br /> Shared Inbox
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is an AI-powered Shared Inbox for Agents?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How Significant Is a Shared Inbox for Your Support Agents?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Benefits of an AI-driven Shared Inbox for Your Support
                  Agents
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workativ Shared Live Inbox: A Wider Use Case for Your
                  Agents’ Productivity
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How Workativ Improves Agents’ Efficiency
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Empowering Live Agents with The AI-powered Shared Live
                  Inbox
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                You are missing out on significant opportunities unless you have
                a convenient way for your virtual employees to solve their
                problems.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.upwork.com/press/releases/third-annual-future-workforce-report">
                  73% of all teams will have remote workers by 2028, a study
                  reveals.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that,{" "}
                <a href="https://financesonline.com/virtual-team-statistics/">
                  75% of employees also say remote collaborations make them more
                  productive and effective{" "}
                </a>
                at work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The evolving work landscape demands a team to sync its{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  live chat
                </span>
                &nbsp;in a way so that communications and coordination can be
                prioritized.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Behind the scenes,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  human agents
                </span>
                &nbsp;are a critical part of the live chat session for your
                internal users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They want a quick answer or solve a problem in real time — live
                chat agents offer more personalized services to ensure a
                seamless user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when you do care for your user’s experience, do you
                ever think of providing an ecosystem that builds great agent
                experience?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  A shared live inbox
                </a>{" "}
                is an intelligent AI-powered live chat screen for agents to
                collaborate, monitor, and respond effectively to users’ queries
                and help them work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s why AI-powered shared inbox is a go-to choice for your IT
                support teams to improve productivity and efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an AI-powered Shared Inbox for Agents?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  shared live inbox
                </span>{" "}
                may likely feel similar to a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  shared email inbox
                </span>{" "}
                many SMBs use to manage employee tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, it is independent of an email management system—and
                more closely an employee support chatbot extension to augment
                the agent’s productivity.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="consolidated view of user tickets in one screen"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  Workativ Shared Live Inbox
                </a>{" "}
                is an{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  agent screen
                </span>{" "}
                embedded in an employee support bot that allows all live agents
                to track user requests looking to connect with agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It gives a unified view of chat status and history, allowing for
                more flexibility for agents to collaborate, assist each other,
                respond to critical tickets, automate workflows, and transfer a
                call to the right agent equipped to handle a specific or more
                critical case.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a shared live inbox, all your agents can work with advanced
                AI tools and technologies and augment employee support for an
                improved experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Significant Is a Shared Inbox for Your Support Agents?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Agent efficiency with a shared inbox "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A survey conducted by Forrester Consulting stated that{" "}
                <a href="https://www.entrepreneur.com/en-in/technology/want-to-build-a-good-bot-take-note-of-these-things/300412">
                  83% of people prefer talking to human agents
                </a>{" "}
                for more critical or complex queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a dated study, but it still holds similar relevance and
                value for today’s evolving employee support.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In the Generative AI era,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  conversational AI
                </span>
                &nbsp;can handle as many common queries as possible. But, human
                agents come in handy when it comes to solving complex user
                problems. There is no match for it.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A shared live inbox combines advanced AI capabilities to reduce
                the complexity of ticket handling for all agents in a seamless
                manner.
              </p>
              <h3 className="font-section-sub-header">
                Here is how a shared live inbox helps your agents:
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Agent performance enhancement with shared inbox for ticket handling "
              />
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Keep all your agents in the loop
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You already know the pain of using a shared mail inbox or a
                conventional helpdesk system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                A shared mail inbox keeps user messages in a thread, neglecting
                important messages or overdoing the exact user requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Conversely, a traditional helpdesk has many components requiring
                a significant learning curve to handle the system and coordinate
                live chat interactions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With a shared inbox for agents, your agents are twice as
                efficient.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none  pl-6">
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    It is easy to pull all user requests into one integrated
                    screen
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                      Agents get maximum flexibility
                    </a>{" "}
                    to monitor requests in a queue all at a time
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Your support team no longer needs to check emails back and
                    forth to track a live chat for an open request
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Agents know who’s handing what and which ticket needs expert
                    assistance
                  </span>
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Deliver real-time resolutions
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                User messages are sometimes hidden in email threads or the
                traditional helpdesk bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                If your team misses messages for lack of visibility or proper
                channel for convenient agent hands-off, real-time interactions
                happen at a later time. Until then, it is too late, and CSAT is
                impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The shared inbox for agents uses AI technology to help agents
                monitor chat requests for live interactions. It ensures which
                chat is longer than the expected wait time or which one is just
                in.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your support team can determine the ticket's priority and offer
                <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                  rapid problem-solving.
                </a>
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Facilitate rapid understanding of user needs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is too apparent to receive vague messages from users. Your
                employees can type words wrong or not get their queries correct.
                In this scenario, live agents are a savior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                  A real-time agent hands-off
                </a>{" "}
                can solve the problems of retyping and waiting in despair for an
                appropriate resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Human agents can understand better what your internal employees
                say instead of vague typed words and offer help that solves
                problems instantly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of an AI-driven Shared Inbox for Your Support Agents
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Benefits of a shared inbox for support agents"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your agents can have immense flexibility while handling internal
                user requests. The benefits of a shared live inbox are enormous
                for your agents, users, and business. Let’s know them.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Rapid response time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You can quickly reduce the wait time for your internal users
                when they want to connect with a live agent. A shared live inbox
                gives a clear view of who’s available and moves the ticket to
                the right person. Your user can get instant help.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Fewer repetitive tickets
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your agents can track ticket status—which are closed and still
                open. This quick view reduces agents' probability of repeatedly
                addressing the same ticket. As a result, support agents handle
                more new requests and minimize employee downtime.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Quicker ticket creation for a more equipped agent
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Often, an agent who handles a ticket may be less equipped to
                handle a specific user case. To provide speed to ticket
                resolution, a shared inbox can help agents escalate the ticket
                to an expert who can resolve a user’s problem at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Appropriate optimization of agent time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents allocate time handling new tickets and never on repeated
                requests. As a result, they have free time to focus on more
                critical user requests and aim for zero inboxes.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Improved CSAT for users
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                A user can instantly contact live agents on the shared live
                inbox. A personalized interaction between a user and an agent
                clarifies ambiguities faster and resolves problems at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Improved SLA for service desk
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is easy to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/internal-sla-targets-achieve-generative-ai">
                  comply with SLA
                </a>{" "}
                for your service desk with a shared inbox’s real-time dashboard.
                Know the number of tickets you handled successfully or where it
                went wrong. Get all the metrics and improve the team’s
                performance for a perfect SLA.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ Shared Live Inbox: A Wider Use Case for Your Agents’
                Productivity
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI, advanced ML, and NLP technology power the shared live inbox
                for agents. It incorporates multiple features to offer your
                agents a more comprehensive range of use cases to maximize
                efficiency and resolve more tickets.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                Multi-channel collaboration
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="multichannel collaboration through a shared inbox for agents "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Our inbox is AI-enhanced and combines user tickets from several
                internal channels. It allows your agents to collaborate faster
                with a user request without the need to toggle in and out of
                several channels and waste time collaborating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per Kayako,{" "}
                <a href="https://kayako.com/blog/5-customer-service-channels-to-consider-in-your-channel-strategy/">
                  56% of agents toggle between screens to find customer
                  information
                </a>
                . This is common with finding information for internal users,
                which consumes enough time for agents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A shared Inbox unifies users’ ticket information onto one
                screen, allowing agents to collaborate faster and resolve
                problems rapidly.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                App workflows for your agents
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="app workflows for agents in a shared live inbox 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Your internal users use multiple apps to be productive with
                their tasks. It is very usual for them to get locked out of
                their apps and face downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service often cannot help if an app account contains a
                unique challenge.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                By creating app workflows for as many apps as you use inside a
                shared inbox, your agents can readily automate workflows and
                help users unlock an account for a particular app.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It does not need your agent to leave the inbox, yet handles
                everything seamlessly to allow users to return to work.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Enhanced teamwork for rapid problem-solving
              </h2>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Increased agent efficiency with a shared inbox 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                With a unified screen for all agents, the shared inbox allows
                your team to get immediate help from a fellow team member in
                case a problem demands more accurate information for resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Tagging a member in the shared inbox using @ mentions to ask for
                help is easy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user needs help with a printer issue. If it is a more
                critical issue than usual, an agent can ask for an article link.
                In this scenario, he can use @ mentions that send real-time
                notifications for immediate response from a team member and get
                help.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                More personalized live interactions
              </h2>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Make interactions personalized with a shared live inbox "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Make every interaction with your users more personalized than
                making it look like bot-created responses.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the AI-enhanced shared inbox, your agent can see the
                complete background of a user, including the ticket history.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It helps your support agents create more personalized
                interactions and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/internal-sla-targets-achieve-generative-ai">
                  increase CSAT
                </a>{" "}
                for your users.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Live agent transfer
              </h2>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt=" a shared inbox for agent efficiency  "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If a query finds no help on a self-service, it can be easily
                transferred to a live agent.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Another amazing thing about the shared inbox is that if a user
                query still looks difficult for your support agent, your team
                can escalate the ticket to the more equipped agent seamlessly
                without extra effort.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Automated responses for faster coordination
              </h2>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="a shared inbox improves response efficiency 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The shared live inbox comes with real-time chat suggestions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using context and intent detection within chat, it is easier for
                your agents to get the best chat suggestions based on user
                queries. This helps reduce the time crafting a new message more
                accurately and solve complex issues faster.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Automated summarization for rapid support
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="a shared inbox improves response efficiency 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Live agent transfer must combine chat summary to assist agents
                and users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But crafting a summary isn't easy, especially for someone
                lacking skills in this particular task.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The intelligent AI summary embedded in the shared inbox enables
                your support team to automate chat summaries instantly and share
                them with the agent to help augment the process of handling
                issues faster.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Workativ Improves Agents’ Efficiency
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Shared Inbox,
                </span>
                &nbsp;your agents can get access to a live agent screen to
                accomplish multiple tasks and help improve service desk
                responses.
              </p>
              <ul className="float-left w-100 blogs_ul_upd">
                <li class="font-section-normal-text-testimonials color-black">
                  Agents no longer rely on guesswork and respond to queries
                  faster.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  For complex queries, agents are able to resolve issues faster
                  by escalating the ticket to the expert agent.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  Human agents can use knowledge articles best to receive
                  automated AI suggestions and speed up response times.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  Agents can help the team offer faster and more accurate
                  responses to solve problems faster.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Empowering Live Agents with The AI-powered Shared Live Inbox
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when Generative AI is becoming a must-have AI tool for
                businesses to enhance workflow automation, businesses are
                looking to maximize AI or GenAI benefits for their employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Our shared live inbox is a perfect AI-driven powerhouse for your
                agents to help augment support and create more loyal employees
                for your business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you are looking to create personalized and automated agent
                support, Workativ's shared live inbox can help to a great
                length.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Are you interested in AI-powered employee support for your
                agents?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Get in touch with us.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a live demo,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  book a call today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                What is a shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  Workativ shared inbox
                </a>{" "}
                gives agents full visibility into user tickets through its
                centralized live agent screen. It consolidates all user requests
                from the employee support bot and aims for faster
                problem-solving through one-to-one live interactions with
                agents.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Is a shared inbox similar to an email shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Basically, a shared inbox is part of an email management system
                to centralize and manage emails or messages for different
                departments in one place. Automated workflows enable teamwork,
                but they focus more on business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Workativ Shared Inbox is meant for employee support agents.
                It works as a live chat window, allowing agents to monitor,
                manage, and resolve tickets as a team. A shared inbox improves
                live chat interactions and helps users get services at scale.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                What are the benefits of using a shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee support shared inbox has many benefits for your
                employee support agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your agents stay in the loop for any coming requests—so no
                tickets are handled twice.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Automated workflows can come in handy to rapidly minimize
                downtime associated with password resets, account unlocks, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Agents can tag others to get immediate help, making it fast to
                build meaningful conversations and solve problems.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is an AI-powered Shared Inbox for Agents?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How Significant Is a Shared Inbox for Your Support
                    Agents?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Benefits of an AI-driven Shared Inbox for Your Support
                    Agents
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Workativ Shared Live Inbox: A Wider Use Case for Your
                    Agents’ Productivity
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How Workativ Improves Agents’ Efficiency
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Empowering Live Agents with The AI-powered Shared Live
                    Inbox
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                You are missing out on significant opportunities unless you have
                a convenient way for your virtual employees to solve their
                problems.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.upwork.com/press/releases/third-annual-future-workforce-report">
                  73% of all teams will have remote workers by 2028, a study
                  reveals.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With that,{" "}
                <a href="https://financesonline.com/virtual-team-statistics/">
                  75% of employees also say remote collaborations make them more
                  productive and effective{" "}
                </a>
                at work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The evolving work landscape demands a team to sync its{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  live chat
                </span>
                &nbsp;in a way so that communications and coordination can be
                prioritized.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Behind the scenes,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  human agents
                </span>
                &nbsp;are a critical part of the live chat session for your
                internal users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They want a quick answer or solve a problem in real time — live
                chat agents offer more personalized services to ensure a
                seamless user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when you do care for your user’s experience, do you
                ever think of providing an ecosystem that builds great agent
                experience?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  A shared live inbox
                </a>{" "}
                is an intelligent AI-powered live chat screen for agents to
                collaborate, monitor, and respond effectively to users’ queries
                and help them work efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s why AI-powered shared inbox is a go-to choice for your IT
                support teams to improve productivity and efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an AI-powered Shared Inbox for Agents?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  shared live inbox
                </span>{" "}
                may likely feel similar to a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  shared email inbox
                </span>{" "}
                many SMBs use to manage employee tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, it is independent of an email management system—and
                more closely an employee support chatbot extension to augment
                the agent’s productivity.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="consolidated view of user tickets in one screen"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  Workativ Shared Live Inbox
                </a>{" "}
                is an{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  agent screen
                </span>{" "}
                embedded in an employee support bot that allows all live agents
                to track user requests looking to connect with agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It gives a unified view of chat status and history, allowing for
                more flexibility for agents to collaborate, assist each other,
                respond to critical tickets, automate workflows, and transfer a
                call to the right agent equipped to handle a specific or more
                critical case.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a shared live inbox, all your agents can work with advanced
                AI tools and technologies and augment employee support for an
                improved experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Significant Is a Shared Inbox for Your Support Agents?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Agent efficiency with a shared inbox "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A survey conducted by Forrester Consulting stated that{" "}
                <a href="https://www.entrepreneur.com/en-in/technology/want-to-build-a-good-bot-take-note-of-these-things/300412">
                  83% of people prefer talking to human agents
                </a>{" "}
                for more critical or complex queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a dated study, but it still holds similar relevance and
                value for today’s evolving employee support.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In the Generative AI era,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  conversational AI
                </span>
                &nbsp;can handle as many common queries as possible. But, human
                agents come in handy when it comes to solving complex user
                problems. There is no match for it.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A shared live inbox combines advanced AI capabilities to reduce
                the complexity of ticket handling for all agents in a seamless
                manner.
              </p>
              <h3 className="font-section-sub-header">
                Here is how a shared live inbox helps your agents:
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Agent performance enhancement with shared inbox for ticket handling "
              />
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Keep all your agents in the loop
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You already know the pain of using a shared mail inbox or a
                conventional helpdesk system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                A shared mail inbox keeps user messages in a thread, neglecting
                important messages or overdoing the exact user requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Conversely, a traditional helpdesk has many components requiring
                a significant learning curve to handle the system and coordinate
                live chat interactions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With a shared inbox for agents, your agents are twice as
                efficient.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none  pl-6">
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    It is easy to pull all user requests into one integrated
                    screen
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                      Agents get maximum flexibility
                    </a>{" "}
                    to monitor requests in a queue all at a time
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Your support team no longer needs to check emails back and
                    forth to track a live chat for an open request
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Agents know who’s handing what and which ticket needs expert
                    assistance
                  </span>
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Deliver real-time resolutions
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                User messages are sometimes hidden in email threads or the
                traditional helpdesk bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                If your team misses messages for lack of visibility or proper
                channel for convenient agent hands-off, real-time interactions
                happen at a later time. Until then, it is too late, and CSAT is
                impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The shared inbox for agents uses AI technology to help agents
                monitor chat requests for live interactions. It ensures which
                chat is longer than the expected wait time or which one is just
                in.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your support team can determine the ticket's priority and offer
                <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                  rapid problem-solving.
                </a>
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Facilitate rapid understanding of user needs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is too apparent to receive vague messages from users. Your
                employees can type words wrong or not get their queries correct.
                In this scenario, live agents are a savior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                  A real-time agent hands-off
                </a>{" "}
                can solve the problems of retyping and waiting in despair for an
                appropriate resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Human agents can understand better what your internal employees
                say instead of vague typed words and offer help that solves
                problems instantly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of an AI-driven Shared Inbox for Your Support Agents
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Benefits of a shared inbox for support agents"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your agents can have immense flexibility while handling internal
                user requests. The benefits of a shared live inbox are enormous
                for your agents, users, and business. Let’s know them.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Rapid response time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You can quickly reduce the wait time for your internal users
                when they want to connect with a live agent. A shared live inbox
                gives a clear view of who’s available and moves the ticket to
                the right person. Your user can get instant help.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Fewer repetitive tickets
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Your agents can track ticket status—which are closed and still
                open. This quick view reduces agents' probability of repeatedly
                addressing the same ticket. As a result, support agents handle
                more new requests and minimize employee downtime.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Quicker ticket creation for a more equipped agent
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Often, an agent who handles a ticket may be less equipped to
                handle a specific user case. To provide speed to ticket
                resolution, a shared inbox can help agents escalate the ticket
                to an expert who can resolve a user’s problem at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Appropriate optimization of agent time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents allocate time handling new tickets and never on repeated
                requests. As a result, they have free time to focus on more
                critical user requests and aim for zero inboxes.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Improved CSAT for users
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                A user can instantly contact live agents on the shared live
                inbox. A personalized interaction between a user and an agent
                clarifies ambiguities faster and resolves problems at scale.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li class="font-section-sub-header-small color-black">
                  Improved SLA for service desk
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is easy to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/internal-sla-targets-achieve-generative-ai">
                  comply with SLA
                </a>{" "}
                for your service desk with a shared inbox’s real-time dashboard.
                Know the number of tickets you handled successfully or where it
                went wrong. Get all the metrics and improve the team’s
                performance for a perfect SLA.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ Shared Live Inbox: A Wider Use Case for Your Agents’
                Productivity
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI, advanced ML, and NLP technology power the shared live inbox
                for agents. It incorporates multiple features to offer your
                agents a more comprehensive range of use cases to maximize
                efficiency and resolve more tickets.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                Multi-channel collaboration
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="multichannel collaboration through a shared inbox for agents "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Our inbox is AI-enhanced and combines user tickets from several
                internal channels. It allows your agents to collaborate faster
                with a user request without the need to toggle in and out of
                several channels and waste time collaborating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per Kayako,{" "}
                <a href="https://kayako.com/blog/5-customer-service-channels-to-consider-in-your-channel-strategy/">
                  56% of agents toggle between screens to find customer
                  information
                </a>
                . This is common with finding information for internal users,
                which consumes enough time for agents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A shared Inbox unifies users’ ticket information onto one
                screen, allowing agents to collaborate faster and resolve
                problems rapidly.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                App workflows for your agents
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="app workflows for agents in a shared live inbox 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Your internal users use multiple apps to be productive with
                their tasks. It is very usual for them to get locked out of
                their apps and face downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service often cannot help if an app account contains a
                unique challenge.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                By creating app workflows for as many apps as you use inside a
                shared inbox, your agents can readily automate workflows and
                help users unlock an account for a particular app.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It does not need your agent to leave the inbox, yet handles
                everything seamlessly to allow users to return to work.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Enhanced teamwork for rapid problem-solving
              </h2>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Increased agent efficiency with a shared inbox 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                With a unified screen for all agents, the shared inbox allows
                your team to get immediate help from a fellow team member in
                case a problem demands more accurate information for resolution.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Tagging a member in the shared inbox using @ mentions to ask for
                help is easy.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user needs help with a printer issue. If it is a more
                critical issue than usual, an agent can ask for an article link.
                In this scenario, he can use @ mentions that send real-time
                notifications for immediate response from a team member and get
                help.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                More personalized live interactions
              </h2>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Make interactions personalized with a shared live inbox "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Make every interaction with your users more personalized than
                making it look like bot-created responses.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the AI-enhanced shared inbox, your agent can see the
                complete background of a user, including the ticket history.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It helps your support agents create more personalized
                interactions and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/internal-sla-targets-achieve-generative-ai">
                  increase CSAT
                </a>{" "}
                for your users.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Live agent transfer
              </h2>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt=" a shared inbox for agent efficiency  "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If a query finds no help on a self-service, it can be easily
                transferred to a live agent.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Another amazing thing about the shared inbox is that if a user
                query still looks difficult for your support agent, your team
                can escalate the ticket to the more equipped agent seamlessly
                without extra effort.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Automated responses for faster coordination
              </h2>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="a shared inbox improves response efficiency 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The shared live inbox comes with real-time chat suggestions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using context and intent detection within chat, it is easier for
                your agents to get the best chat suggestions based on user
                queries. This helps reduce the time crafting a new message more
                accurately and solve complex issues faster.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Automated summarization for rapid support
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="a shared inbox improves response efficiency 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Live agent transfer must combine chat summary to assist agents
                and users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But crafting a summary isn't easy, especially for someone
                lacking skills in this particular task.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The intelligent AI summary embedded in the shared inbox enables
                your support team to automate chat summaries instantly and share
                them with the agent to help augment the process of handling
                issues faster.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Workativ Improves Agents’ Efficiency
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Shared Inbox,
                </span>
                &nbsp;your agents can get access to a live agent screen to
                accomplish multiple tasks and help improve service desk
                responses.
              </p>
              <ul className="float-left w-100 blogs_ul_upd">
                <li class="font-section-normal-text-testimonials color-black">
                  Agents no longer rely on guesswork and respond to queries
                  faster.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  For complex queries, agents are able to resolve issues faster
                  by escalating the ticket to the expert agent.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  Human agents can use knowledge articles best to receive
                  automated AI suggestions and speed up response times.
                </li>
                <li class="font-section-normal-text-testimonials color-black">
                  Agents can help the team offer faster and more accurate
                  responses to solve problems faster.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Empowering Live Agents with The AI-powered Shared Live Inbox
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when Generative AI is becoming a must-have AI tool for
                businesses to enhance workflow automation, businesses are
                looking to maximize AI or GenAI benefits for their employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Our shared live inbox is a perfect AI-driven powerhouse for your
                agents to help augment support and create more loyal employees
                for your business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you are looking to create personalized and automated agent
                support, Workativ's shared live inbox can help to a great
                length.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Are you interested in AI-powered employee support for your
                agents?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Get in touch with us.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a live demo,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  book a call today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                What is a shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  Workativ shared inbox
                </a>{" "}
                gives agents full visibility into user tickets through its
                centralized live agent screen. It consolidates all user requests
                from the employee support bot and aims for faster
                problem-solving through one-to-one live interactions with
                agents.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Is a shared inbox similar to an email shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Basically, a shared inbox is part of an email management system
                to centralize and manage emails or messages for different
                departments in one place. Automated workflows enable teamwork,
                but they focus more on business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Workativ Shared Inbox is meant for employee support agents.
                It works as a live chat window, allowing agents to monitor,
                manage, and resolve tickets as a team. A shared inbox improves
                live chat interactions and helps users get services at scale.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                What are the benefits of using a shared inbox?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee support shared inbox has many benefits for your
                employee support agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your agents stay in the loop for any coming requests—so no
                tickets are handled twice.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Automated workflows can come in handy to rapidly minimize
                downtime associated with password resets, account unlocks, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Agents can tag others to get immediate help, making it fast to
                build meaningful conversations and solve problems.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk"
              className="font-section-normal-text-testimonials"
            >
              The ROI of Using Chatbot and Automation with Your IT Service Desk​
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management"
              className="font-section-normal-text-testimonials"
            >
              AI in ITSM: Unlocking the Value of AI for IT Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
